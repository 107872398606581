html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.min-vh-100 { min-height: 100vh !important; }

.logo_container {
  display:table;
  height:200px;
  width:200px;
  margin:0 auto;   
  text-align:center;
}
.logo {
  display:table-cell;
  vertical-align:middle;
}
.logo img {
  max-height:80%;
  max-width:80%;
}

.canvas-fit{
  display: block;
  height: 100%;
  width: 100%;
}

.align-items-center {
  align-items: center!important;
}

.d-flex {
  display: flex!important;
}

.ml-2, .mx-2 {
  margin-left: .5rem!important;
}

.ml-4, .mx-4 {
  margin-left: 1rem!important;
}
.mr-4, .mx-4 {
  margin-right: 1rem!important;
}

.product-image-container {
  width: 50px;
  height: 50px;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}
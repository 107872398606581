body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.input--file {
  position: relative;
  top: 3px;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 30px;
}
.MuiCollapse-wrapperInner {
  width: 70% !important;
}

.image-container {
  height: calc(100vh - 150px);
  width: 100%;
  object-fit: cover;
  display: inline-block;
  position: relative;
}
.object-fit-image {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.image-container-default {
  height: calc(100vh - 150px);
  display: flex;
}
.object-fit-image-default {
  max-height: 100%; 
  width: 100%;
  object-fit: contain;
}